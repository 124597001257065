import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSectTitle,
  CPostCard03,
} from '../../../components/_index';
import planChoice from '../../../utils/plan-choice';
import planGet from '../../../utils/plan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'activity',
            ja: 'アクティビティ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/todo/activity/kv.png',
              },
              imgSp: {
                src: '/assets/images/todo/activity/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '過ごす',
                path: '/experience/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[0]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              心身を整える
              <br />
              杜の都の寛ぎと癒やし
            </h2>
            <p className="c_sectLead">
              日々の喧騒を忘れる杜の都のリゾートアクティビティ。
              <br />
              自然に囲まれた環境ならではの開放感溢れる体験を自由におたのしみいただけます。
            </p>
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{ ja: '開催中アクティビティ', en: 'IN SESSION' }}
            />
            <CPostCard03 data={planChoice(planGet(), ['アクティビティ'], 0)} />
          </LWrap>
        </section>
      </div>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
